import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ParallaxContext, useController } from 'react-scroll-parallax';
// components
import * as Styled from 'src/components/rise-chicago/RiseChicagoStyled';
import SEO from 'src/components/SEO';
import { NikeIcon } from 'src/components/svgs';
import ZoomVideo from 'src/components/ZoomVideo';
import SectionTrigger from 'src/components/SectionTrigger';
import ScrollScene from 'src/components/rise-chicago/RiseChicagoScrollScene';
// lib
import handlePageTransition from 'src/lib/handle-page-transition';

// eslint-disable-next-line react/prop-types
function RiseChicagoPage({ transitionStatus }) {

  const isServer = typeof window === 'undefined';
  const context = React.useContext(ParallaxContext)

  if (!isServer && context ) {
    const { parallaxController } = useController();
    handlePageTransition(parallaxController, transitionStatus);
  }

  const data = useStaticQuery(graphql`
    query RiseChicagoPage {
      allCloudinaryMedia(
        filter: { tags: { eq: "rise-chicago-case-study" } }
        sort: { fields: public_id, order: ASC }
      ) {
        edges {
          node {
            resource_type
            secure_url
          }
        }
      }
      allVimeo(
        filter: { name: { regex: "/RISE_CHICAGO_CASE_STUDY/" } }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            srcset {
              link
            }
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const vimeoMedia = data.allVimeo.edges;
  const images = [];
  const videos = [];
  cloudMedia.forEach(item => {
    images.push(item.node.secure_url);
  });
  vimeoMedia.forEach(item => {
    const { srcset } = item.node;
    videos.push(srcset);
  });
  const [img1, img2, img13, img14, img15, img16, img18, img19, img20] = images;
  // const [summerHoopsVid, chiHoopsVid] = videos;
  const summerHoopsVid =
    'https://player.vimeo.com/external/433464929.hd.mp4?s=0fe8d88ebdb8b0786a2d38f34646ff2e4f3dc916&profile_id=174&oauth2_token_id=1365998561';
  const chiHoopsVid =
    'https://player.vimeo.com/external/433464936.sd.mp4?s=a37974b32b74e4eaad07924b2824bc70a5ab65f8&profile_id=164&oauth2_token_id=1365998561';

  return (
    <div>
      <SEO title="Rise Chicago | Falkon Content" />
      <div className="rise-chicago">
        <Styled.Section1>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section1Img1>
                <img src={img1} alt="no alt" />
              </Styled.Section1Img1>
              <Styled.Section1Title y={[0, -100]}>
                <NikeIcon modifier="rise-chicago__icon--nike" />
                <h1 className="main-heading">Rise Chicago</h1>
                <p className="subTitle">
                  Nike Basketball Chicago
                  <br />
                  Together We Rise
                </p>
              </Styled.Section1Title>
            </div>
          </div>
        </Styled.Section1>
        <Styled.Section2>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section2Blurb1>
                <p>
                  How do you take the energy and resources of Nike and use it to
                  save lives?
                </p>
              </Styled.Section2Blurb1>
              <Styled.Section2Heading1 y={[0, 50]}>
                <h2 className="main-heading">Challenge</h2>
              </Styled.Section2Heading1>
              <Styled.Section2Img1 y={[0, -20]}>
                <img src={img2} alt="no alt" />
              </Styled.Section2Img1>
              <Styled.Section2Heading2 y={[-100, 0]}>
                <h2 className="main-heading">Solution</h2>
              </Styled.Section2Heading2>
              <Styled.Section2Blurb2 y={[0, -75]}>
                <p>
                  Expand the popular Chi League Basketball Tournament from a
                  single night to a special event that spans the five most
                  violent weekends of the summer to inspire the community to get
                  off the streets and unite around their love of the game.
                </p>
              </Styled.Section2Blurb2>
            </div>
          </div>
        </Styled.Section2>
        <SectionTrigger bgColor="#9DBECD" textColor="#fff">
          <ZoomVideo autoPlayVideo={true} src={summerHoopsVid} />
        </SectionTrigger>
        <Styled.Section3>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section3Heading1 y={[100, -100]}>
                <h2 className="main-heading">Execution</h2>
              </Styled.Section3Heading1>
              <Styled.Section3Blurb1 y={[25, 50]}>
                <p>
                  Partner with local artists to transform run-down courts.
                  Develop the Program Platform: tournament brackets, schedule &
                  overall event framework. Concept and Produce a launch film
                  announcing the program. Capture & Deploy authentic & engaging
                  content for social and Deploy across the breadth of the event.
                  A finale film that celebrates the event and shouts out
                  Chicago’s rich community.
                </p>
              </Styled.Section3Blurb1>
            </div>
          </div>
        </Styled.Section3>
        <ScrollScene />
        <Styled.Section4>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section4Heading1 y={[0, -150]}>
                <h2 className="main-heading">
                  The Finale
                  <br />
                  The Finale
                  <br />
                  The Finale
                </h2>
              </Styled.Section4Heading1>
              <Styled.Section4Blurb1 y={[-15, 10]}>
                <p>
                  A celebratory film recapped the experience, reflecting on the
                  hope and change that the love of the game brings when an
                  entire community unites around it.
                </p>
              </Styled.Section4Blurb1>
            </div>
          </div>
        </Styled.Section4>
        <Styled.Section5>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section5Img1 y={[20, -20]}>
                <img src={img13} alt="player dunking a basketball" />
              </Styled.Section5Img1>
              <Styled.Section5Img2 y={[-20, 0]}>
                <img src={img14} alt="basketball coach instructing player" />
              </Styled.Section5Img2>
              <Styled.Section5Img3 y={[0, 20]}>
                <img src={img15} alt="group of kids sitting on bleachers" />
              </Styled.Section5Img3>
              <Styled.Section5Img4 y={[0, -30]}>
                <img
                  src={img16}
                  alt="player jumping over kids to slam dunk basketball"
                />
              </Styled.Section5Img4>
            </div>
          </div>
        </Styled.Section5>
        <SectionTrigger bgColor="#507D9B" textColor="#fff">
          <ZoomVideo autoPlayVideo={true} src={chiHoopsVid} />
        </SectionTrigger>
        <Styled.Section6>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section6Heading1 y={[0, -20]}>
                <h2 className="main-heading">
                  Impact
                  <br />
                  Impact
                  <br />
                  Impact
                  <br />
                  Impact
                </h2>
              </Styled.Section6Heading1>
              <Styled.Section6Blurb1 y={[-20, 0]}>
                <p>
                  A final event - featuring a Kids All-Star game and competition
                  for the Chi League trophy.
                </p>
              </Styled.Section6Blurb1>
              <Styled.Section6Img1>
                <img src={img18} className="global-box-shadow" alt="no alt" />
              </Styled.Section6Img1>
              <Styled.Section6Blurb2 y={[50, -50]}>
                <p>
                  We highlighted the kids that excelled while, everyone was
                  celebrated on{' '}
                  <a
                    href="https://www.instagram.com/nikechicago/?hl=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @nikechicago
                  </a>
                </p>
              </Styled.Section6Blurb2>
              <Styled.Section6Img2 y={[-20, 20]}>
                <img src={img19} className="global-box-shadow" alt="no alt" />
              </Styled.Section6Img2>
              <Styled.Section6Img3 y={[50, -50]}>
                <img src={img20} className="global-box-shadow" alt="no alt" />
              </Styled.Section6Img3>
            </div>
          </div>
        </Styled.Section6>
        <Styled.Section7>
          <div className="grid__container">
            <div className="grid">
              <Styled.Section7Quote>
                <blockquote>
                  <p>
                    Over 500 kids entered the program
                    <br />
                    2,200 attendees
                    <br />
                    36% decrease in violent crimes from previous summers
                    <br />
                    Mission accomplished.
                    <br />
                  </p>
                </blockquote>
              </Styled.Section7Quote>
            </div>
          </div>
        </Styled.Section7>
      </div>
    </div>
  );
}

export default React.memo(RiseChicagoPage);
