import React, { useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
// utils
import { above, size } from 'src/utils';

const HozStyled = styled.div`
  overflow: hidden;

  #pinContainer {
    overflow: hidden;
    width: 100vw;
    height: 100vh;

    .horizontal-wrapper {
      display: flex;
      position: relative;
    }

    .panel {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
    }

    .panel--last {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .last-image {
      width: 100vw;
      height: auto;

      ${above.large`
        width: ${size.large}px;
      `}

      ${above.xl`
        width: ${size.xl}px;
      `}

      ${above.huge`
        width: ${size.huge}px;
      `}
    }
  }
`;

const Panel2 = styled.div`
  display: grid;
  grid-auto-columns: 6vw;
  grid-column-gap: 20px;
  height: 100vh;
  align-items: center;
  padding-right: 20px;

  ${above.huge`
    grid-auto-columns: calc(6px/100 * 1600);
  `}
`;

const Panel2Quote1 = styled.div`
  grid-area: 1 / 2 / auto / 13;
  z-index: 2;

  ${above.huge`
    blockquote p {
      font-size: 90px;
    }
  `}
`;

const Panel2Title1 = styled.div`
  grid-area: 1 / 13 / auto / 15;
  z-index: 2;
  transform: translateX(-50%);
  margin-top: 23vw;

  ${above.huge`
    margin-top: calc(23px/100 * 1600);
  `}

  h3 {
    font-size: 1.25vw;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: underline;

    ${above.huge`
      font-size: 20px;
    `}
  }
`;

const Panel2Img1 = styled.div`
  position: relative;
  grid-area: 1 / 13 / auto / 17;
  align-self: center;
  z-index: 1;

  .main-heading--south-side {
    font-size: 2.75vw;
    position: absolute;
    top: -60px;
    left: -80px;
    z-index: 10;
    color: transparent;
    -webkit-text-stroke: 0.04em #fff;

    &::before,
    &::after {
      display: block;
      content: 'South Side';
    }

    ${above.huge`
      font-size: calc(2.75px/100 * 1600);
    `}
  }

  .caption {
    text-transform: uppercase;
    font-size: 1vw;

    ${above.huge`
      font-size: 16px;
    `}
  }
`;

const Panel2Img2 = styled.div`
  position: relative;
  grid-area: 1 / 16 / auto / 20;
  align-self: center;

  .main-heading--west-side {
    font-size: 2.75vw;
    position: absolute;
    bottom: -75px;
    right: -45px;
    z-index: 10;
    color: transparent;
    -webkit-text-stroke: 0.04em #fff;

    &::before,
    &::after {
      display: block;
      content: 'West Side';
    }

    ${above.huge`
      font-size: calc(2.75px/100 * 1600);
    `}
  }

  .caption {
    text-transform: uppercase;
    text-align: right;
    font-size: 1vw;

    ${above.huge`
      font-size: 16px;
    `}
  }
`;

const Panel2Quote2 = styled.div`
  grid-area: 1 / 19 / auto / 26;
  z-index: 2;

  ${above.huge`
    blockquote p {
      font-size: 90px;
    }
  `}
`;

const Panel2Img3 = styled.div`
  grid-area: 1 / 21 / auto / 28;
  align-self: center;
`;

const Panel2Img4 = styled.div`
  grid-area: 1 / 28 / auto / 36;
  align-self: center;
`;

const Panel2Quote3 = styled.div`
  grid-area: 1 / 35 / auto / 43;
  z-index: 2;
  transform: translateX(-50%);

  ${above.huge`
    blockquote p {
      font-size: 90px;
    }
  `}
`;

const Panel2Img5 = styled.div`
  grid-area: 1 / 36 / auto / 40;
  align-self: center;
`;

const Panel2Img6 = styled.div`
  grid-area: 1 / 40 / auto / 44;
  align-self: center;
`;

const Panel2Img7 = styled.div`
  grid-area: 1 / 44 / auto / 51;
  align-self: center;
`;

const Panel2Quote4 = styled.div`
  z-index: 2;
  grid-area: 1 / 44 / auto / 51;
  transform: translateX(50%);
  ${above.huge`
    blockquote p {
      font-size: 90px;
    }
  `}
`;

function RiseChicagoScrollGallery() {
  const data = useStaticQuery(graphql`
    query RiseChicagoScrollGallery {
      allCloudinaryMedia(
        filter: { tags: { eq: "rise-chicago-scroll-gallery" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `);
  const cloudMedia = data.allCloudinaryMedia.edges;
  const images = [];
  cloudMedia.map(image => images.push(image.node.secure_url));
  const [img5, img6, img7, img8, img9, img10, img11, img12] = images;
  const [width, setWidth] = useState(0);
  const [minusWidth, setMinusWidth] = useState(0);
  const ref = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setWidth(width + w);
    }
  }, []);

  const minusRef = useCallback(node => {
    if (node !== null) {
      const w = node.scrollWidth;
      setMinusWidth(w);
    }
  }, []);

  return (
    <HozStyled>
      <Controller>
        <Scene triggerHook="onLeave" duration={width} pin>
          <Timeline wrapper={<div id="pinContainer" ref={ref} />}>
            <Tween to={{ x: `-${width - minusWidth}` }}>
              <section className="horizontal-wrapper">
                <Panel2>
                  <Panel2Quote1>
                    <blockquote>
                      <p>
                        &ldquo;We went into the toughest neighborhoods
                        ...&rdquo;
                      </p>
                    </blockquote>
                  </Panel2Quote1>
                  <Panel2Title1>
                    <h3>Artist Courts</h3>
                  </Panel2Title1>
                  <Panel2Img1>
                    <h3 className="main-heading main-heading--south-side">
                      South Side
                    </h3>
                    <img src={img5} alt="no alt" />
                    <p className="caption">South Side x Max Sansing</p>
                  </Panel2Img1>
                  <Panel2Img2>
                    <h3 className="main-heading main-heading--west-side">
                      West Side
                    </h3>
                    <p className="caption">West Side x PScott</p>
                    <img src={img6} alt="no alt" />
                  </Panel2Img2>
                  <Panel2Quote2>
                    <blockquote>
                      <p>&ldquo;...and partnered with artists ...&rdquo;</p>
                    </blockquote>
                  </Panel2Quote2>
                  <Panel2Img3>
                    <img src={img7} alt="no alt" />
                  </Panel2Img3>
                  <Panel2Img4>
                    <img src={img8} alt="no alt" />
                  </Panel2Img4>
                  <Panel2Quote3>
                    <blockquote>
                      <p>&ldquo;...to turn the local courts into ...&rdquo;</p>
                    </blockquote>
                  </Panel2Quote3>
                  <Panel2Img5>
                    <img src={img9} alt="no alt" />
                  </Panel2Img5>
                  <Panel2Img6>
                    <img src={img10} alt="no alt" />
                  </Panel2Img6>
                  <Panel2Img7>
                    <img src={img11} alt="no alt" />
                  </Panel2Img7>
                  <Panel2Quote4>
                    <blockquote>
                      <p>&ldquo;...summer long safe havens&rdquo;</p>
                    </blockquote>
                  </Panel2Quote4>
                </Panel2>
                <div className="panel panel--last" ref={minusRef}>
                  <img className="last-image" src={img12} alt="no tag" />
                </div>
              </section>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </HozStyled>
  );
}

export default RiseChicagoScrollGallery;
