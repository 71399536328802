import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import SectionTrigger from '../SectionTrigger';
// utils
import { above } from '../../utils';

export const Section1 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#EFF5FA',
  textColor: '#000',
}))`
  .subTitle {
    text-transform: uppercase;
    margin: 0;
  }
`;

export const Section1Img1 = styled.div`
  grid-area: 1 / 1 / auto / 4;
  z-index: 1;
`;

export const Section1Title = styled(Parallax)`
  grid-area: 2 / 3 / auto / 10;
  z-index: 3;

  .rise-chicago__icon--nike {
    width: 10%;
  }
`;

export const Section2 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#EFF5FA',
  textColor: '#000',
}))``;

export const Section2Blurb1 = styled(Parallax)`
  grid-area: 1 / 5 / auto / 9;
  z-index: 3;
`;

export const Section2Heading1 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 13;
  z-index: 1;
  text-align: right;
`;
export const Section2Img1 = styled(Parallax)`
  grid-area: 3 / 1 / auto / 13;
  z-index: 2;
`;
export const Section2Heading2 = styled(Parallax)`
  grid-area: 4 / 1 / auto / 13;
  z-index: 1;
  h2 {
    &::before,
    &::after {
      display: block;
      content: 'Solution';
    }
  }
`;
export const Section2Blurb2 = styled(Parallax)`
  grid-area: 5 / 6 / auto / 12;
  z-index: 3;
`;

export const Section3 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#9DBECD',
  textColor: '#fff',
}))``;

export const Section3Heading1 = styled(Parallax)`
  grid-area: 1 / 3 / auto / 11;
  z-index: 12;
  text-align: center;
  h2 {
    margin: 0;
    &::before,
    &::after {
      display: block;
      content: 'Execution';
    }
  }
`;
export const Section3Blurb1 = styled(Parallax)`
  grid-area: 2 / 7 / auto / 13;
  z-index: 3;
`;

export const Section4 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#9DBECD',
  textColor: '#fff',
}))``;

export const Section4Heading1 = styled(Parallax)`
  grid-area: 1 / 1 / auto / 7;
  z-index: 12;
  h2 {
    margin: 0;
  }
`;
export const Section4Blurb1 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 5;
  z-index: 3;
`;

export const Section5 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#EFF5FA',
  textColor: '#000',
}))`
  padding: 10% 0;
  img {
    width: 100%;
  }
`;

export const Section5Img1 = styled(Parallax)`
  grid-area: 1 / 5 / auto / 10;
  z-index: 2;
`;
export const Section5Img2 = styled(Parallax)`
  grid-area: 2 / 1 / auto / 7;
  z-index: 1;
`;
export const Section5Img3 = styled(Parallax)`
  grid-area: 2 / 7 / auto / 13;
  z-index: 1;
`;
export const Section5Img4 = styled(Parallax)`
  grid-area: 3 / 5 / auto / 9;
  z-index: 2;
`;

export const Section6 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#507D9B',
  textColor: '#fff',
}))``;

export const Section6Heading1 = styled(Parallax)`
  grid-area: 1 / 2 / auto / 12;
  z-index: 12;
  h2 {
    margin: 0;
  }
`;
export const Section6Blurb1 = styled(Parallax)`
  grid-area: 2 / 2 / auto / 5;
  z-index: 12;
`;

export const Section6Img1 = styled(Parallax)`
  grid-area: 3 / 1 / auto / 7;
  z-index: 12;
`;

export const Section6Blurb2 = styled(Parallax)`
  grid-area: 3 / 7 / auto / 10;
  z-index: 12;
`;

export const Section6Img2 = styled(Parallax)`
  grid-area: 4 / 7 / auto / 13;
  z-index: 12;
`;

export const Section6Img3 = styled(Parallax)`
  grid-area: 5 / 4 / auto / 10;
  z-index: 12;
`;

export const Section7 = styled(SectionTrigger).attrs(() => ({
  bgColor: '#507D9B',
  textColor: '#fff',
}))`
  margin: 10% 0;
`;

export const Section7Quote = styled(Parallax)`
  grid-area: 1 / 1 / auto / 13;
  z-index: 1;
  blockquote {
    p {
      text-transform: uppercase;
      font-size: 5vw;
      line-height: 1.25;
      letter-spacing: 0;

      ${above.huge`
        font-size: 80px;
      `}
    }
  }
`;